import React, { useState, useEffect } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Axios from '../../config/axios';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Fragment } from 'react';
import Moment from 'moment';
import { useForm, Controller } from "react-hook-form";
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Table, Spin, Alert, Empty } from 'antd';
import { toast, confirm } from '@rickylandino/react-messages';

export default function CVRequestManageHoursSlider(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const [state, setState] = useState({
        showPane: false,
        dataLoaded: false,
        hoursForWeekEnding: [],
        request: {},
        employeesList: [],
        editMode: 'add'
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
    const [currentPage, setCurrentPage] = useState([1]);
    const [currentPageSize, setCurrentPageSize] = useState([10]);

    useEffect(() => {
        setValue("formFields", props.request);
        setValue("formFields.selectedDate", new Date());
        setValue("formFields.weekEndingDate", (getWeekEndingDate(new Date())).toLocaleDateString('en-US'));
        setValue("formFields.weekEndingDateStr", (getWeekEndingDateStr(getWeekEndingDate(new Date()))));
        setValue('formFields.hrsTot', '0');
        setValue('formFields.selectedEmployeeType', '');
        setValue('formFields.editMode', 'add');
        setValue('formFields.selectedEmployeeId', '');
        setValue('formFields.currentSavedEmployee', null);

        console.log(props.request);

        LoadEmployeesForContractor();
    }, [props]);

    function LoadEmployeesForContractor() {
        Axios.get(`/api/GetEmployeesForContractor`, {
            params: {
                contractorID: getValues().formFields.mrRequest.contractors_ID
            }
        }).then(response => {
            let employeesList = response.data;

            Axios.get(`/api/GetMarketRecoveryHoursForWeekEnding`, {
                params: {
                    requestID: getValues().formFields.mrRequest.requestID,
                    contractorID: getValues().formFields.mrRequest.contractors_ID,
                    weekEnding: getValues().formFields.weekEndingDateStr
                }
            }).then(response => {
                let hoursList = response.data.hoursList;
                let totalHoursPaid = response.data.totalHoursPaid;
                let totalHoursPending = response.data.totalHoursPending;

                console.log(response.data);
                console.log(hoursList);
                console.log(totalHoursPaid);

                setTotalHoursForWeekEnding(hoursList, totalHoursPaid, totalHoursPending);

                setState({
                    ...state,
                    request: props.request,
                    hoursForWeekEnding: hoursList,
                    employeesList,
                    dataLoaded: true,
                    showPane: true
                });

            }).catch(error => {
                console.log(error);
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function LoadHoursForWeekEnding() {
        Axios.get(`/api/GetMarketRecoveryHoursForWeekEnding`, {
            params: {
                requestID: getValues().formFields.mrRequest.requestID,
                contractorID: getValues().formFields.mrRequest.contractors_ID,
                weekEnding: getValues().formFields.weekEndingDateStr
            }
        }).then(response => {
            let hoursList = response.data.hoursList;
            let totalHoursPaid = response.data.totalHoursPaid;
            let totalHoursPending = response.data.totalHoursPending;

            console.log(hoursList);

            setTotalHoursForWeekEnding(hoursList, totalHoursPaid, totalHoursPending);

            setState({
                ...state,
                request: props.request,
                hoursForWeekEnding: hoursList,
                dataLoaded: true,
                showPane: true
            });

            console.log(hoursList);

        }).catch(error => {
            console.log(error);
        });
    }

    //async function DoHoursExistForEmployee() {
    //    await Axios.get(`/api/GetEmployeeHours`, {
    //        params: {
    //            requestID: getValues().formFields.mrRequest.requestID,
    //            contractorID: getValues().formFields.mrRequest.contractors_ID,
    //            weekEnding: getValues().formFields.weekEndingDateStr,
    //            ssNumber: getValues().formFields.selectedEmployeeSSNum,
    //            employee: getValues().formFields.selectedEmployeeName
    //        }
    //    }).then(response => {
    //        let hoursList = response.data;
    //        console.log(hoursList.length);
    //        if (hoursList.length > 0) {
    //            console.log('returning true');
    //            return true;
    //        }
    //        else {
    //            console.log('returning false');
    //            return false;
    //        }
    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    function hidePane() {
        setState({
            ...state,
            showPane: false,
        });
    }

    function WeekdayHeaderFormatter(dayOfWeek) {
        var numOfDays = 0;

        switch (dayOfWeek) {
            case 'Mon':
                numOfDays = 6;
                break;
            case 'Tue':
                numOfDays = 5;
                break;
            case 'Wed':
                numOfDays = 4;
                break;
            case 'Thu':
                numOfDays = 3;
                break;
            case 'Fri':
                numOfDays = 2;
                break;
            case 'Sat':
                numOfDays = 1;
                break;
            case 'Sun':
                numOfDays = 0;
                break;
            default:
            
        }

        const calcDate = new Date(getValues().formFields.weekEndingDate);
        calcDate.setDate(calcDate.getDate() - numOfDays);

        let month = (calcDate.getMonth() + 1).toString();
        let day = (calcDate.getDate()).toString();
        let myHeaderText = dayOfWeek + ' ' + month + '/' + day;

        return (
            <div>{myHeaderText}</div>
        );
    }

    function hoursTotalFormatter(cell, row) {
        var myTot = row.mon + row.tue + row.wed + row.thu + row.fri + row.sat + row.sun;

        return (
            <div>{myTot}</div>
        );
    }

    const columns = [
        {
            title: 'marketRecoveryHours_ID', dataIndex: 'marketRecoveryHours_ID', key: 'marketRecoveryHours_ID', hidden: true
        }, {
            title: 'weekEnding', dataIndex: 'weekEnding', key: 'weekEnding', hidden: true
        }, {
            title: 'checkNo', dataIndex: 'checkNo', key: 'checkNo', hidden: true
        }, {
            dataIndex: 'employee',
            title: 'Employee',
            key: 'employee',
            sorter: (a, b) => a.employee.localeCompare(b.employee)
        }, {
            dataIndex: 'ssNumber',
            title: 'SS #',
            key: 'ssNumber',
            sorter: (a, b) => a.employee.localeCompare(b.employee)
        }, {
            dataIndex: 'employee',
            title: 'Employee',
            key: 'employee',
            sorter: (a, b) => a.employee.localeCompare(b.employee)
        }, {
            dataIndex: 'mon',
            key: 'mon',
            title: () => (
                WeekdayHeaderFormatter('Mon')
            ),
            //sorter: (a, b) => a.mon.localeCompare(b.mon),
            align: 'center',
            width: 80
        }, {
            dataIndex: 'tue',
            key: 'tue',
            title: () => (
                WeekdayHeaderFormatter('Tue')
            ),
            //sorter: (a, b) => a.tue.localeCompare(b.tue),
            align: 'center',
            width: 80
        }, {
            dataIndex: 'wed',
            key: 'wed',
            title: () => (
                WeekdayHeaderFormatter('Wed')
            ),
            //sorter: (a, b) => a.wed.localeCompare(b.wed),
            align: 'center',
            width: 80
        }, {
            dataIndex: 'thu',
            key: 'thu',
            title: () => (
                WeekdayHeaderFormatter('Thu')
            ),
            //sorter: (a, b) => a.thu.localeCompare(b.thu),
            align: 'center',
            width: 80
        }, {
            dataIndex: 'fri',
            key: 'fri',
            title: () => (
                WeekdayHeaderFormatter('Fri')
            ),
            //sorter: (a, b) => a.fri.localeCompare(b.fri),
            align: 'center',
            width: 80
        }, {
            dataIndex: 'sat',
            key: 'sat',
            title: () => (
                WeekdayHeaderFormatter('Sat')
            ),
            //sorter: (a, b) => a.sat.localeCompare(b.sat),
            align: 'center',
            width: 80
        }, {
            dataIndex: 'sun',
            key: 'sun',
            title: () => (
                WeekdayHeaderFormatter('Sun')
            ),
            //sorter: (a, b) => a.sun.localeCompare(b.sun),
            align: 'center',
            width: 80
        }, {
            title: 'Type',
            key: 'employeeType',
            width: 120,
            render: (cell, row, idx) => row.employeeType === 'J' ? <div>Journeyman</div> : <div>Apprentice</div>,
            align: 'center'
        }, {
            title: 'Total',
            key: 'total',
            width: 120,
            render: (cell, row) => hoursTotalFormatter(cell, row),
            align: 'center'
        }, {
            title: '',
            key: 'action',
            width: 60,
            render: (record) => (
                <span className="hover" onClick={(e) => loadSelectedHours(e, record)}><i id="editHours" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }, {
            title: '',
            key: 'action2',
            width: 60,
            render: (record) => record.checkNo === '' || record.checkNo === null ? <span className="hover" onClick={(e) => handleDelete(e, record)}><i id="deleteHours" className="far fa-trash-alt fa-1x ml-3 text-center hover text-danger"></i></span> : <span></span>,
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function loadSelectedHours(e, row) {
        onResetHoursClick();
        setValue('formFields.marketRecoveryHours_ID', row.marketRecoveryHours_ID);
        setValue('formFields.selectedEmployeeName', row.employee);
        setValue('formFields.selectedEmployeeSSNum', row.ssNumber);
        setValue('formFields.hrsMon', row.mon);
        setValue('formFields.hrsTue', row.tue);
        setValue('formFields.hrsWed', row.wed);
        setValue('formFields.hrsThu', row.thu);
        setValue('formFields.hrsFri', row.fri);
        setValue('formFields.hrsSat', row.sat);
        setValue('formFields.hrsSun', row.sun);
        setValue('formFields.selectedEmployeeType', row.employeeType);
        setValue('formFields.editMode', 'edit');
        setValue('formFields.origHours', row.mon + row.tue + row.wed + row.thu + row.fri + row.sat + row.sun);

        handleRefreshTotalClick();

        setState({ ...state });
    }

    function handleDelete(e, record) {
        confirm({
            title: "You are about to permanently delete the selected hours",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                Axios.post(`/api/DeleteMarketRecoveryHours`, record
                ).then(response => {
                    if (response.data) {
                        toast.success("Selected hours have been deleted");
                        LoadHoursForWeekEnding();
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    //const pagination = paginationFactory({
    //    page: tableControl.page,
    //    sizePerPage: tableControl.sizePerPage,
    //    showTotal: true,
    //    alwaysShowAllBtns: true,
    //    onPageChange: function (page, sizePerPage) {

    //        setTableControl({
    //            ...tableControl,
    //            page
    //        });
    //    },
    //    onSizePerPageChange: function (page, sizePerPage) {

    //    }
    //});

    //const selectRow = {
    //    mode: 'radio',
    //    clickToSelect: true,
    //    style: { backgroundColor: '#C5DBEC' },
    //    hideSelectColumn: true
    //};

    function handleSelectedDateChange(date) {
        setValue('formFields.selectedDate', date);
        setValue("formFields.weekEndingDate", (getWeekEndingDate(date)).toLocaleDateString('en-US'));
        setValue("formFields.weekEndingDateStr", (getWeekEndingDateStr(getWeekEndingDate(date))));
        setState({ ...state });

        LoadHoursForWeekEnding();
    }

    const getWeekEndingDate = (date) => {
        const now = date ? new Date(date) : new Date().setHours(0, 0, 0, 0);
        const sunday = new Date(now);

        if (sunday.getDay() !== 0) {
            sunday.setDate(sunday.getDate() - sunday.getDay() + 7);
        }
        else {
            sunday.setDate(sunday.getDate() - sunday.getDay() + 0);
        }

        return sunday;
    }

    const getWeekEndingDateStr = (date) => {
        let year = date.getFullYear().toString();
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();

        if (month.length == 1) { month = '0' + month; }
        if (day.length == 1) { day = '0' + day; }

        return year + month + day;
    }

    function endOfWeek(date) {

        var lastday = date.getDate() - (date.getDay() - 1) + 6;
        return new Date(date.setDate(lastday));

    }

    function handleSelectedEmployeeChange(event) {
        const target = event.target;
        var value = null;
        if (target.type === 'checkbox') {
            value = target.checked === true ? 1 : 0;
        }
        else {
            value = target.value;
        }
        const name = target.name;

        //Init in case employee is not selected
        setValue('formFields.selectedEmployeeId', '');
        setValue('formFields.selectedEmployeeName', '');
        setValue('formFields.selectedEmployeeSSNum', '');

        state.employeesList.forEach((emp, idx) => {
            if (emp.employees_ID.toString() === value) {
                //console.log(emp);
                setValue('formFields.currentSavedEmployee', emp);
                setValue('formFields.selectedEmployeeId', emp.employees_ID);
                setValue('formFields.selectedEmployeeName', emp.employeeName);
                setValue('formFields.selectedEmployeeSSNum', emp.ssNumber);
            }
        });

        setState({ ...state });
    }

    function setTotalHoursForWeekEnding(hoursList, totalHoursPaid, totalHoursPending) {
        let myTotalHoursForTheWeek = 0;

        hoursList.forEach((hrs, idx) => {
            myTotalHoursForTheWeek += hrs.mon + hrs.tue + hrs.wed + hrs.thu + hrs.fri + hrs.sat + hrs.sun;
        });

        setValue('formFields.totalHoursForTheWeek', myTotalHoursForTheWeek);
        setValue('formFields.totalHoursPaid', totalHoursPaid);
        setValue('formFields.totalHoursPending', totalHoursPending);
    }

    function onAddHoursClick() {
        //this is so we can back out the hours being updated if user is editing the row
        var hoursBeingEdited = 0;

        if (screenDataOK() === false) {
            return;
        }

        if (getValues().formFields?.totalHoursPaid > state.request.mrRequest?.grantedHours) {

        }

        let myMonHours = 0;
        let myTueHours = 0;
        let myWedHours = 0;
        let myThuHours = 0;
        let myFriHours = 0;
        let mySatHours = 0;
        let mySunHours = 0;
        let myDollarsPerHour = 0;

        if (hoursBeingEdited) { hoursBeingEdited = Number(getValues().formFields.origHours); }
        if (getValues().formFields?.hrsMon !== '') { myMonHours = Number(getValues().formFields?.hrsMon); }
        if (getValues().formFields?.hrsTue !== '') { myTueHours = Number(getValues().formFields?.hrsTue); }
        if (getValues().formFields?.hrsWed !== '') { myWedHours = Number(getValues().formFields?.hrsWed); }
        if (getValues().formFields?.hrsThu !== '') { myThuHours = Number(getValues().formFields?.hrsThu); }
        if (getValues().formFields?.hrsFri !== '') { myFriHours = Number(getValues().formFields?.hrsFri); }
        if (getValues().formFields?.hrsSat !== '') { mySatHours = Number(getValues().formFields?.hrsSat); }
        if (getValues().formFields?.hrsSun !== '') { mySunHours = Number(getValues().formFields?.hrsSun); }

        console.log(getValues().formFields.origHours);
        //console.log(Number(state.request.mrRequest.hoursPaidManually));
        //console.log(Number(getValues().formFields?.totalHoursPaid));
        //console.log(Number(getValues().formFields?.totalHoursPending));
        //console.log(hoursBeingEdited);

        if ((myMonHours + myTueHours + myWedHours + myThuHours + myFriHours + mySatHours + mySunHours + Number(state.request.mrRequest.hoursPaidManually) + Number(getValues().formFields?.totalHoursPaid) + Number(getValues().formFields?.totalHoursPending) - hoursBeingEdited) > Number(state.request.mrRequest.grantedHours)) {
            let message = "Hours Submitted Is More Than Hours Granted by Local 21 For The Project<br /><br />Please Submit Hours Not To Exceed Hours Granted<br /><br />";
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

            return;
        }

        //Prep data for API add or update
        let postdata = {}
        postdata.RequestID = getValues().formFields.mrRequest.requestID;
        postdata.Contractors_ID = getValues().formFields.mrRequest.contractors_ID;
        postdata.WeekEnding = getValues().formFields.weekEndingDateStr;
        postdata.SSNumber = getValues().formFields.selectedEmployeeSSNum;
        postdata.Employee = getValues().formFields.selectedEmployeeName;
        postdata.Mon = myMonHours;
        postdata.Tue = myTueHours;
        postdata.Wed = myWedHours;
        postdata.Thu = myThuHours;
        postdata.Fri = myFriHours;
        postdata.Sat = mySatHours;
        postdata.Sun = mySunHours;
        postdata.DateSubmitted = new Date();

        if (getValues().formFields.mrRequest.dollarsPerHourApprentice !== null) {
            postdata.EmployeeType = getValues().formFields.selectedEmployeeType;
            if (getValues().formFields.selectedEmployeeType === 'J') {
                myDollarsPerHour = getValues().formFields.mrRequest.dollarsPerHour;
            }
            else {
                myDollarsPerHour = getValues().formFields.mrRequest.dollarsPerHourApprentice;
            }
        }
        else {
            postdata.EmployeeType = "J";
            myDollarsPerHour = getValues().formFields.mrRequest.dollarsPerHour;
        }
        postdata.DollarsPerHour = myDollarsPerHour;

        if (getValues().formFields.editMode === 'edit') {
            postdata.MarketRecoveryHours_ID = getValues().formFields.marketRecoveryHours_ID;

            Axios.post(`/api/UpdateMarketRecoveryHours`, postdata
            ).then(response => {
                toast.success("Hours Updated Successfully");
                onResetHoursClick();

                LoadHoursForWeekEnding();
            }).catch(error => {
                toast.error("Problem Encountered Updating Hours");

                console.log(error);
            });
        }
        else {
            //console.log(state.hoursForWeekEnding);
            let hoursFoundForEmployee = false;

            state.hoursForWeekEnding.forEach((hrs, idx) => {
                if (hrs.requestID === getValues().formFields.mrRequest.requestID &&
                    hrs.contractors_ID === getValues().formFields.mrRequest.contractors_ID &&
                    hrs.weekEnding === getValues().formFields.weekEndingDateStr.toString() &&
                    hrs.ssNumber === getValues().formFields.selectedEmployeeSSNum.toString() &&
                    hrs.employee === getValues().formFields.selectedEmployeeName.toString()) {
                    
                    hoursFoundForEmployee = true;
                }
            });

            if (hoursFoundForEmployee === true) {
                let message = "This employee already has hours applied for this time period<br /><br />Update their existing hours if they are different than what was already submitted<br /><br />"
                toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

                return;
            }
            else {
                Axios.post(`/api/AddNewMarketRecoveryHours`, postdata
                ).then(response => {
                    toast.success("Hours Added Successfully");
        
                    LoadHoursForWeekEnding();
                }).catch(error => {
                    toast.error("Problem Encountered Adding Hours");

                    console.log(error);
                });
            }

            //now loop through employees list to see if employee needs to be added to employees table so they can just be selected next time from dropdown
            let employeeAlreadyInList = false;

            //console.log(state.employeesList);
            //console.log(getValues().formFields.mrRequest.contractors_ID);
            //console.log(getValues().formFields.selectedEmployeeSSNum.toString());
            //console.log(getValues().formFields.selectedEmployeeName.toString());

            state.employeesList.forEach((emp, idx) => {
                if (emp.contractors_ID === getValues().formFields.mrRequest.contractors_ID && 
                    emp.ssNumber === getValues().formFields.selectedEmployeeSSNum &&
                    emp.employeeName === getValues().formFields.selectedEmployeeName) {
                    employeeAlreadyInList = true;
                }
            });

            if (employeeAlreadyInList === false) {
                let emppostdata = {};
                emppostdata.Contractors_ID = getValues().formFields.mrRequest.contractors_ID;
                emppostdata.EmployeeName = getValues().formFields.selectedEmployeeName;
                emppostdata.SSNumber = getValues().formFields.selectedEmployeeSSNum;

                Axios.post(`/api/AddNewEmployee`, emppostdata
                ).then(response => {
                    let newEmployee = {
                        employees_ID: 11000,
                        contractors_ID: emppostdata.Contractors_ID,
                        employeeName: emppostdata.EmployeeName,
                        ssNumber: emppostdata.SSNumber
                    }
                    let newemployeelist = state.employeesList;
                    newemployeelist.push(newEmployee);
                    console.log(newemployeelist);
                    setState({
                        ...state,
                        employeesList: newemployeelist
                    });
                }).catch(error => {
                    toast.error("Problem Encountered Adding Employee");

                    console.log(error);
                });
            }

            onResetHoursClick();
        }
    }

    function onResetHoursClick() {
        setValue('formFields.selectedEmployee', 'Select Employee');
        setValue('formFields.selectedEmployeeId', '');
        setValue('formFields.selectedEmployeeName', '');
        setValue('formFields.selectedEmployeeSSNum', '');
        setValue('formFields.currentSavedEmployee', null);
        setValue('formFields.hrsMon', '');
        setValue('formFields.hrsTue', '');
        setValue('formFields.hrsWed', '');
        setValue('formFields.hrsThu', '');
        setValue('formFields.hrsFri', '');
        setValue('formFields.hrsSat', '');
        setValue('formFields.hrsSun', '');
        setValue('formFields.hrsTot', '0');
        setValue('formFields.selectedEmployeeType', '');
        setValue('formFields.editMode', 'add');
        setValue('formFields.origHours', 0);

        setState({ ...state });
    }

    function handleEmployeeTypeChange(event) {
        const target = event.target;
        var value = null;
        if (target.type === 'checkbox') {
            value = target.checked === true ? 1 : 0;
        }
        else {
            value = target.value;
        }
        const name = target.name;

        setValue('formFields.selectedEmployeeType', value);

        setState({ ...state });
    }

    function handleRefreshTotalClick() {
        let myHrsTot = 0;

        if (getValues().formFields?.hrsMon !== '') { myHrsTot += Number(getValues().formFields?.hrsMon); }
        if (getValues().formFields?.hrsTue !== '') { myHrsTot += Number(getValues().formFields?.hrsTue); }
        if (getValues().formFields?.hrsWed !== '') { myHrsTot += Number(getValues().formFields?.hrsWed); }
        if (getValues().formFields?.hrsThu !== '') { myHrsTot += Number(getValues().formFields?.hrsThu); }
        if (getValues().formFields?.hrsFri !== '') { myHrsTot += Number(getValues().formFields?.hrsFri); }
        if (getValues().formFields?.hrsSat !== '') { myHrsTot += Number(getValues().formFields?.hrsSat); }
        if (getValues().formFields?.hrsSun !== '') { myHrsTot += Number(getValues().formFields?.hrsSun); }

        setValue('formFields.hrsTot', myHrsTot);

        setState({ ...state });
    }

    function screenDataOK() {
        let message = '';
        let myHrsTot = 0;

        if (getValues().formFields.selectedEmployeeName === '') {
            message += '&bull;&nbsp;Employee Name</br>';
        }
        if (getValues().formFields.selectedEmployeeSSNum === '') {
            message += '&bull;&nbsp;SS Number (Last 4)</br>';
        }

        //if dollarsPerHourApprentice is null, the EmployeeType will get defaulted to 'J' before saving to the DB so no need to make mandatory
        if (getValues().formFields.mrRequest.dollarsPerHourApprentice !== null && getValues().formFields.selectedEmployeeType === '') {
            message += '&bull;&nbsp;Employee Type</br>';
        }

        if (getValues().formFields?.hrsMon !== '') { myHrsTot += Number(getValues().formFields?.hrsMon); }
        if (getValues().formFields?.hrsTue !== '') { myHrsTot += Number(getValues().formFields?.hrsTue); }
        if (getValues().formFields?.hrsWed !== '') { myHrsTot += Number(getValues().formFields?.hrsWed); }
        if (getValues().formFields?.hrsThu !== '') { myHrsTot += Number(getValues().formFields?.hrsThu); }
        if (getValues().formFields?.hrsFri !== '') { myHrsTot += Number(getValues().formFields?.hrsFri); }
        if (getValues().formFields?.hrsSat !== '') { myHrsTot += Number(getValues().formFields?.hrsSat); }
        if (getValues().formFields?.hrsSun !== '') { myHrsTot += Number(getValues().formFields?.hrsSun); }
        if (myHrsTot === 0) {
            message += '&bull;&nbsp;No Hours Specified</br>';
        }

        if (message != '') {
            message = "<strong>Mandatory fields missing or invalid data specified</strong><br />" + message;
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

            return false;
        }
        else {
            return true;
        }
    }

    function handleRemoveSavedEmployeeClick() {
        let employee = getValues().formFields.currentSavedEmployee;
        console.log(employee);

        Axios.post(`/api/DeleteEmployee`, employee
        ).then(response => {
            if (response.data) {
                toast.success("Saved employee has been deleted");

                onResetHoursClick();
                setValue('formFields.selectedEmployee', 'Select Employee');

                setState({
                    ...state,
                    employeesList: response.data
                });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function updatePageState(page, pageSize) {
        setCurrentPage(page);
        setCurrentPageSize(pageSize);

        setState({
            ...state,
        });
    }

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title='Submit Hours For Reimbursement'
            onRequestClose={hidePane}
            width={'80%'}
        >
            <div className="slide-pane-body scrollableDiv">
                <div className="row">
                    <div className="form-group col-lg-12 d-flex">
                        <label className="form-label mb-0">Job Name:</label>&nbsp;
                        <label className="form-label color-dark-blue mb-0">{state.request.mrRequest?.projectName} - {state.request.mrRequest?.typeOfHours}</label>
                    </div>

                    <div className="form-group col-lg-2 d-flex">
                        <label className="form-label">Hours Granted:</label>&nbsp;
                        <label className="form-label color-dark-blue">{state.request.mrRequest?.grantedHours}</label>
                    </div>
                    <div className="form-group col-lg-2 d-flex">
                        <label className="form-label">System Hours Paid To Date:</label>&nbsp;
                        <label className="form-label color-dark-blue">{getValues().formFields?.totalHoursPaid}</label>
                    </div>
                    <div className="form-group col-lg-3 d-flex">
                        <label className="form-label">Hours Submitted But Still Pending:</label>&nbsp;
                        <label className="form-label color-dark-blue">{getValues().formFields?.totalHoursPending}</label>
                    </div>
                    <div className="form-group col-lg-2 d-flex">
                        <label className="form-label">Hours Paid Manually:</label>&nbsp;
                        <label className="form-label color-dark-blue">{state.request.mrRequest?.hoursPaidManually}</label>
                    </div>
                    <div className="form-group col-lg-3 d-flex">
                        <span style={getValues().formFields?.totalHoursPaid + getValues().formFields?.totalHoursPending > state.request.mrRequest?.grantedHours ? {} : { display: 'none' }}><label className="form-label" style={{ color: 'red' }}>SUBMITTED HOURS EXCEED GRANTED HOURS</label></span>
                    </div>

                    <div className="form-group col-lg-12 d-flex">
                        <label className="form-label">Select Date:</label>&nbsp;
                        <Controller
                            name="formFields.selectedDate"
                            control={control}
                            setValue={setValue}
                            defaultValue={getValues().formFields?.selectedDate ? Moment(getValues().formFields.selectedDate).toDate() : null}
                            render={() =>
                                <DatePicker
                                    selected={getValues().formFields?.selectedDate ? Moment(getValues().formFields.selectedDate).toDate() : null}
                                    onChange={date => handleSelectedDateChange(date)}
                                    className="form-control-custom"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    customInput={
                                        <NumberFormat format="##/##/####" mask="_" />
                                    } />
                            }
                        />&nbsp;&nbsp;&nbsp;
                        <label className="form-label">(Week Ending {getValues().formFields?.weekEndingDate})</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <label className="form-label">Total Hours Reported For Week Ending {getValues().formFields?.weekEndingDate}:&nbsp;</label><label className="form-label color-dark-blue mb-0">{getValues().formFields?.totalHoursForTheWeek}</label>
                    </div>

                    <div className="form-group col-lg-12">
                        {state.dataLoaded &&
                            <Fragment>
                                {state.hoursForWeekEnding.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                        <span>
                                            No hours found for the selected check
                                        </span>
                                    } />
                                    :
                                    <div>
                                        <Table className="custom-ant-selection"
                                            rowKey={(record) => record.marketRecoveryHours_ID}
                                            rowSelection={rowSelection}
                                            hideSelectionColumn={true}
                                            bordered
                                            size="small"
                                            dataSource={state.hoursForWeekEnding}
                                            style={{ whiteSpace: 'pre' }}
                                            columns={columns}
                                            pagination={{
                                                defaultPageSize: 10,
                                                showSizeChanger: true,
                                                pageSizeOptions: ['10', '25', '50', '100'],
                                                showTotal: (total, range) => (
                                                    <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                        Showing {range[0]}-{range[1]} of {total}
                                                    </span>
                                                ),
                                                onChange: (page, pageSize) => {
                                                    updatePageState(page, pageSize);
                                                },
                                                current: currentPage,
                                                pageSize: currentPageSize
                                            }}
                                            onRow={(record, index) => {
                                                return {
                                                    onClick: () => {
                                                        let selRows = [record.marketRecoveryHours_ID];
                                                        setSelectedRowKeys([...selRows]);
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                }
                            </Fragment>

                            //<Fragment>
                            //    {state.hoursForWeekEnding.length === 0 ?
                            //        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                            //            <span>
                            //                No hours have been submitted for this job for week ending selected
                            //            </span>
                            //        } />
                            //        :
                            //        <div className="fullTable">
                            //            <ToolkitProvider
                            //                keyField='marketRecoveryHours_ID'
                            //                data={state.hoursForWeekEnding}
                            //                columns={columns}
                            //            >
                            //                {props => (
                            //                    <div>
                            //                        <BootstrapTable
                            //                            {...props.baseProps}
                            //                            striped
                            //                            hover
                            //                            condensed
                            //                            pagination={pagination}
                            //                            rowEvents={rowEvents}
                            //                            selectRow={selectRow}
                            //                        />
                            //                    </div>
                            //                )}
                            //            </ToolkitProvider>
                            //        </div>
                            //    }
                            //</Fragment>
                        }
                    </div>

                    <div className="col-lg-12">
                        {getValues('formFields.editMode') === 'add' ?
                            <label className="form-label color-dark-blue">Add Hours For Employee (Select from list or add new employee by entering their Name and SS Number)</label>
                        :
                            <label className="form-label color-dark-blue">Update Hours For {getValues().formFields?.selectedEmployeeName}</label>
                        }
                    </div>
                    <div className="col-lg-12">
                        <label className="form-label mt-n5">New Employees will get added to your list of saved employees for easier selection next time</label>
                    </div>

                    <div className="form-group col-lg-2">
                        <label className="form-label">Saved Employees:</label>
                    </div>
                    <div className="form-group col-lg-4">   
                        <select className="form-control-custom" {...register("formFields.selectedEmployee")} onChange={handleSelectedEmployeeChange} disabled={getValues('formFields.editMode') === 'add' ? false : true}>
                            <option>Select Employee</option>
                            {state.employeesList?.length > 0 && state.employeesList?.map((emp, idx) => <option index={idx} key={emp.employees_ID} value={emp.employees_ID}>{emp.employeeName}</option>)}
                        </select>
                    </div>
                    <div className="form-group col-lg-6">
                        <button className="btn btn-submit btn-sm" onClick={handleRemoveSavedEmployeeClick} disabled={getValues('formFields.selectedEmployeeId') === '' ? true : false}>Remove Saved Employee</button>
                    </div>

                    <div className="form-group col-lg-4">
                        <label className="form-label">Employee</label>
                        <input type="text" {...register("formFields.selectedEmployeeName")} className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-4">
                        <label className="form-label">SS # (Last 4)</label>
                        <input type="text" {...register("formFields.selectedEmployeeSSNum")} className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-4">
                        {state.request.mrRequest?.dollarsPerHourApprentice !== null &&
                            <fragment>
                                <label className="form-label">Employee Type</label><br />
                                <span><input type="radio" name="employeeType" value="J" checked={getValues().formFields?.selectedEmployeeType === 'J'} onChange={handleEmployeeTypeChange} /></span>&nbsp;Journeyman
                                <span style={{ "paddingLeft": "10px" }}><input type="radio" name="employeeType" value="A" checked={getValues().formFields?.selectedEmployeeType === 'A'} onChange={handleEmployeeTypeChange} /></span>&nbsp;Apprentice
                            </fragment>
                        }
                    </div>

                    <div className="form-group col-lg-1">
                        <label className="form-label">Mon</label>
                        <input type="number" min="0" {...register("formFields.hrsMon")} className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-1">
                        <label className="form-label">Tue</label>
                        <input type="number" min="0" {...register("formFields.hrsTue")} className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-1">
                        <label className="form-label">Wed</label>
                        <input type="number" min="0" {...register("formFields.hrsWed")} className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-1">
                        <label className="form-label">Thu</label>
                        <input type="number" min="0" {...register("formFields.hrsThu")} className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-1">
                        <label className="form-label">Fri</label>
                        <input type="number" min="0" {...register("formFields.hrsFri")} className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-1">
                        <label className="form-label">Sat</label>
                        <input type="number" min="0" {...register("formFields.hrsSat")} className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-1">
                        <label className="form-label">Sun</label>
                        <input type="number" min="0" {...register("formFields.hrsSun")} className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-1">
                        {/*<label className="form-label">Total</label><br />*/}
                        {/*<label className="form-label color-dark-blue" {...register("formFields.hrsTot")}>{getValues().formFields?.hrsTot}</label>*/}
                    </div>
                    <div className="form-group col-lg-2">
                        <br />
                        {/*<button className="btn btn-submit btn-sm" onClick={handleRefreshTotalClick}>Refresh Total</button>*/}
                    </div>
                    <div className="form-group col-lg-2"></div>

                    <div className="form-group col-lg-12">
                        
                        <button className="btn btn-submit btn-sm" onClick={onAddHoursClick}>{getValues('formFields.editMode') === 'add' ? 'Add Hours' : 'Update Hours'}</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <button className="btn btn-submit btn-sm" onClick={onResetHoursClick}>Reset Hours</button>
                    </div>

                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>

        </SlidingPane>
    );
}