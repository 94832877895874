import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import 'antd/dist/antd.css';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Select } from 'antd';
import { useForm, Controller } from "react-hook-form";
import Card from 'react-bootstrap/Card';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import statesList from '../Helpers/StatesList';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';

export default function CVAddRequestSlider(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    const { Option } = Select;

    const [state, setState] = useState({
        formFields: {},
        states: statesList,
        showPane: props.showPane,
        prevailingWageJob: '',
        typeOfHoursOptions: [],
        cities: [],
        processingNewRequest: false
    });

    const { register, getValues, setValue, control, watch } = useForm();

    function onSearch(val) {
        //console.log('search:', val);
    }

    useEffect(() => {
        setValue('formFields', {});

        //LoadTestData();

        LoadTypesOfHours();

        const subscription = watch((value, { name, type }) => {
            //console.log(value);
            //console.log(name);
            //console.log(type);
        });

        return () => subscription.unsubscribe();

    }, [props]);

    function LoadTypesOfHours() {
        let typeOfHoursOptions = [];

        typeOfHoursOptions.push(<option key='Select'>Select</option>);
        typeOfHoursOptions.push(<option key='Plumbing'>Plumbing</option>);
        typeOfHoursOptions.push(<option key='HVAC'>HVAC</option>);
        typeOfHoursOptions.push(<option key='Mechanical'>Mechanical</option>);
        typeOfHoursOptions.push(<option key='Plumbing & HVAC'>Plumbing & HVAC</option>);
        typeOfHoursOptions.push(<option key='Plumbing & Mechanical'>Plumbing & Mechanical</option>);
        typeOfHoursOptions.push(<option key='HVAC Service'>HVAC Service</option>);

        setState({
            ...state,
            typeOfHoursOptions: typeOfHoursOptions
        });
    }

    function LoadTestData() {
        setValue('formFields.bidDate', new Date());
        setValue('formFields.projectName', 'Daves Test Project');
        setValue('formFields.projectDetails', 'Building 1 conference room');
        setValue('formFields.jobsiteAddress', '850 Sherman Ave');
        setValue('formFields.city', 'Hamden');
        setValue('formFields.state', 'CT');
        setValue('formFields.zip', '06518');
        setValue('formFields.typeOfHours', 'Plumbing Forman');
        setValue('formFields.requestedHours', '100');
        setValue('formFields.awardingParty', 'Landino Construction');
        setValue('formFields.jobsiteContactPerson', 'DJL');
        setValue('formFields.jobsiteContactPhone', '860-965-9916');
        setValue('formFields.workDescription', 'This is just a test project to test submitting a new request for MR');
        setValue('formFields.unionBidder1', 'Union Co. 1');
        setValue('formFields.nonUnionBidder1', 'Non Union Co. 1');      
    }

    function LoadCities() {
        Axios.get(`/api/GetCities`, {
            params: {
                
            }
        }).then(response => {
            let cities = response.data;

            setState({
                ...state,
                cities,
                showPane: props.showPane,
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function handleSubmitRequest() {
        setState({
            ...state,
            processingNewRequest: true
        });

        setValue('formFields.jobsiteCityState', getValues().formFields.city + ', ' + getValues().formFields.state + ' ' + getValues().formFields.zip);
        console.log(getValues().formFields.typeOfHours)

        let message = "";

        if (getValues().formFields.bidDate === '' || getValues().formFields.bidDate === null || typeof getValues().formFields.bidDate === 'undefined') {
            message += '&bull;&nbsp;Invalid Bid Date specified</br>';
        }
        //if (typeof getValues().formFields.contractors_ID === 'undefined' || getValues().formFields.contractors_ID == '') {
        //    message += '&bull;&nbsp;Contractor is required</br>';
        //}
        //if (typeof getValues().formFields.contacts_ID === 'undefined' || getValues().formFields.contacts_ID == '') {
        //    message += '&bull;&nbsp;Contact is required</br>';
        //}
        if (typeof getValues().formFields.projectName === 'undefined' || getValues().formFields.projectName == '') {
            message += '&bull;&nbsp;Project Name is required</br>';
        }
        if (typeof getValues().formFields.jobsiteAddress === 'undefined' || getValues().formFields.jobsiteAddress == '') {
            message += '&bull;&nbsp;Jobsite Street Address is required</br>';
        }
        if (typeof getValues().formFields.city === 'undefined' || getValues().formFields.city == '' || getValues().formFields.city == 'Select City' || typeof getValues().formFields.state === 'undefined' || getValues().formFields.state == '' || getValues().formFields.state == 'Select State' || typeof getValues().formFields.zip === 'undefined' || getValues().formFields.zip == '') {
            message += '&bull;&nbsp;Jobsite City, State & Zip is required</br>';
        }
        if (typeof getValues().formFields.requestedHours === 'undefined' || getValues().formFields.requestedHours == '') {
            message += '&bull;&nbsp;Requested Hours is required</br>';
        }
        if (typeof getValues().formFields.typeOfHours === 'undefined' || getValues().formFields.typeOfHours == 'Select' || getValues().formFields.typeOfHours == '') {
            message += '&bull;&nbsp;Type of Hours is required</br>';
        }
        if (typeof getValues().formFields.workDescription === 'undefined' || getValues().formFields.workDescription == '') {
            message += '&bull;&nbsp;Work Description is required</br>';
        }
        if (typeof getValues().formFields.awardingParty === 'undefined' || getValues().formFields.awardingParty == '') {
            message += '&bull;&nbsp;Awarding Party is required</br>';
        }
        if (typeof getValues().formFields.unionBidder1 === 'undefined' || getValues().formFields.unionBidder1 == '') {
            message += '&bull;&nbsp;Union Bidder (mimimum of 1 is required)</br>';
        }
        if (typeof getValues().formFields.nonUnionBidder1 === 'undefined' || getValues().formFields.nonUnionBidder1 == '') {
            message += '&bull;&nbsp;Non Union Bidder (mimimum of 1 is required)</br>';
        }
        console.log(message);
        if (message != '') {
            message = "<strong>Mandatory fields missing or invalid data specified</strong><br />" + message;
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

            setState({
                ...state,
                processingNewRequest: false
            });

            return;
        }
        else {
            //Save New Request
            SaveNewRequest();
        }
    }

    function getLocalDateTime(date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        let myDatePart = year + '-' + month + '-' + day;
        let myTimePart = new Date().toLocaleTimeString([], { hour12: false });

        const myDate = new Date(myDatePart + ' ' + myTimePart);
        const userTimezoneOffset = myDate.getTimezoneOffset() * 60000;

        return new Date(myDate.getTime() - userTimezoneOffset);
    }

    function SaveNewRequest() {
        let postdata = getValues().formFields;
        postdata.RequestStatus = 'Pending';
        postdata.RequestDate = getLocalDateTime(new Date());
        postdata.Contractors_ID = userInfo.contractors_ID;
        postdata.Contacts_ID = userInfo.contacts_ID;
        postdata.bidDate = getLocalDateTime(getValues().formFields.bidDate);
        if (state.prevailingWageJob === 'YES') {
            postdata.prevailingWageJob = 1;
        }
        else {
            postdata.prevailingWageJob = 0;
        }
        postdata.Creator = userInfo.fullName;

        console.log(postdata);

        Axios.post('/api/InsertRequest', postdata
        ).then(async response => {
            console.log(response.data);
            
            let postdata = {
                RequestID: response.data,
                MCACMember: userInfo.mcacMember
                //Username: userInfo.fullName
            }

            Axios.post(`/api/SendEmailNotificationsToLocal21`, postdata
            ).then(response => {
                if (response.data === true) {
                    toast.success('New Request Submitted Successfully');
                    hidePane();
                } else {
                    toast.error("Request submitted but there was a problem sending email notifications");
                    hidePane();
                }
            }).catch(error => {
                console.log(error);
            });
        }).catch(error => {
            toast.error("Problem encountered submitting Request for Market Recovery. Please contact Local 21 to report this problem.");
            console.log(error);
        });
    }

    function hidePane() {
        //for graceful, animated close
        setTimeout(() => props.updateTableDisplay(), 1000);

        setState({
            ...state,
            showPane: false
        });
    }

    function handleprevailingWageJobChange(event) {
        const target = event.target;

        let typeOfHoursOptions = [];

        typeOfHoursOptions.push(<option key='Select'>Select</option>);
        typeOfHoursOptions.push(<option key='Plumbing'>Plumbing</option>);
        typeOfHoursOptions.push(<option key='HVAC'>HVAC</option>);
        typeOfHoursOptions.push(<option key='Mechanical'>Mechanical</option>);
        typeOfHoursOptions.push(<option key='Plumbing & HVAC'>Plumbing & HVAC</option>);
        typeOfHoursOptions.push(<option key='Plumbing & Mechanical'>Plumbing & Mechanical</option>);
        typeOfHoursOptions.push(<option key='HVAC Service'>HVAC Service</option>);
        typeOfHoursOptions.push(<option key='HVAC & Mechanical'>HVAC & Mechanical</option>);

        //if (target.value === 'YES') {
        //    typeOfHoursOptions.push(<option key='Select'>Select</option>);
        //    typeOfHoursOptions.push(<option key='Plumbing Forman'>Plumbing Forman</option>);
        //    typeOfHoursOptions.push(<option key='Plumbing Off Site Fab'>Plumbing Off Site Fab</option>);
        //    typeOfHoursOptions.push(<option key='HVAC Forman'>HVAC Forman</option>);
        //    typeOfHoursOptions.push(<option key='HVAC Off Site Fab'>HVAC Off Site Fab</option>);
        //    typeOfHoursOptions.push(<option key='Plumbing & HVAC Forman'>Plumbing & HVAC Forman</option>);
        //    typeOfHoursOptions.push(<option key='Plumbing & HVAC Fabrication'>Plumbing & HVAC Fabrication</option>);
        //}
        //else {
        //    //typeOfHoursOptions = ['Select', 'Plumbing'];
        //    typeOfHoursOptions.push(<option key='Select'>Select</option>);
        //    typeOfHoursOptions.push(<option key='Plumbing'>Plumbing</option>);
        //    typeOfHoursOptions.push(<option key='HVAC'>HVAC</option>);
        //    typeOfHoursOptions.push(<option key='Plumbing & HVAC'>Plumbing & HVAC</option>);
        //}

        setState({
            ...state,
            prevailingWageJob: target.value,
            typeOfHoursOptions: typeOfHoursOptions
        });
    }

    return (
        <>
            <SlidingPane
                className='some-custom-class w-65'
                overlayClassName='showCard'
                isOpen={state.showPane}
                title='New Request for Market Recovery'
                onRequestClose={hidePane}
            >
                <div className="slide-pane-body scrollableDiv">
                    <div>
                        {!state.loading &&
                            <Fragment>
                                <form>
                                    <div className="row">
                                        <div className="form-group col-lg-12">
                                            <span style={{ color: 'red', fontWeight: 'bold'}}>CONTRACTORS PLEASE NOTE</span>
                                            <ol>
                                                <li style={{ margin: '0px !important'}}>All fields must be completed or this request will automatically be denied.</li>
                                                <li>After 90 days from Bid Date, request is VOID. If your request becomes active again, submit a new request and reference this request in the work description.</li>
                                                <li>If approved at $10/hour or more it is on a one to one basis. For every person from the company working on the job site with the tools, there will be an equal
                                                    amount of personnel sent from the Union Hall's "Out of Work List". Any violation of this agreement will cancel further payments for Market Recovery for the job. If you have any questions,
                                                    please call.
                                                </li>
                                            </ol>
                                        </div>
                                        <div className="form-group col-lg-12 d-flex">
                                            <label className="form-label">Bid Date:</label>&nbsp;
                                            <Controller
                                                name="formFields.bidDate"
                                                control={control}
                                                setValue={setValue}
                                                defaultValue={getValues().formFields?.bidDate ? Moment(getValues().formFields.bidDate).toDate() : null}
                                                render={() =>
                                                    <DatePicker
                                                        selected={getValues().formFields?.bidDate ? Moment(getValues().formFields.bidDate).toDate() : null}
                                                        onChange={date => setValue('formFields.bidDate', date)}
                                                        className="form-control-custom"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        customInput={
                                                            <NumberFormat format="##/##/####" mask="_" />
                                                        } />
                                                }
                                            />
                                        </div>

                                        {/*<div className="form-group col-lg-12">*/}
                                        {/*    <label className="form-label color-dark-blue">JOB INFORMATION</label>*/}
                                        {/*</div>*/}

                                        <div className="form-group col-lg-4">
                                            <label className="form-label">Project Name</label>
                                            <input type="text" {...register("formFields.projectName")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <label className="form-label">Project Details</label>
                                            <input type="text" {...register("formFields.projectDetails")} className="form-control-custom" placeholder='Building/Suite/Floor (or other details if applicable)' />
                                        </div>
                                        <div className="form-group col-lg-4">
                                            {/*<label className="form-label">Prevailing Wage Job</label>*/}
                                            {/*<div className="header-search pull-left">*/}
                                            {/*    <span><input type="radio" name="prevailingWageJob" value="YES" checked={state.prevailingWageJob === 'YES'} onChange={handleprevailingWageJobChange} /></span>&nbsp;YES*/}
                                            {/*    <span style={{ "paddingLeft": "10px" }}><input type="radio" name="prevailingWageJob" value="NO" checked={state.prevailingWageJob === 'NO'} onChange={handleprevailingWageJobChange} /></span>&nbsp;NO*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <label className="form-label">Jobsite Address</label>
                                            <input type="text" {...register("formFields.jobsiteAddress")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <label className="form-label">City</label>
                                            {/*<select className="form-control-custom" {...register("formFields.city")}>*/}
                                            {/*    <option value=''>Select City</option>*/}
                                            {/*    {state.cities.map((city, idx) => <option key={idx} value={city.city}>{city.city}</option>)}*/}
                                            {/*</select>*/}
                                            <input type="text" {...register("formFields.city")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">State</label>
                                            <select className="form-control-custom" {...register("formFields.state")}>
                                                <option value=''>Select State</option>
                                                {/*<option value="NY">NY</option>*/}
                                                {state.states.map((state, idx) => <option key={idx} value={state.abbreviation}>{state.name}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Zip</label>
                                            <input type="text" {...register("formFields.zip")} className="form-control-custom" />
                                        </div>

                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Requested Hours</label>
                                            <input type="text" {...register("formFields.requestedHours")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Type of Hours</label>
                                            <select className="form-control-custom" {...register("formFields.typeOfHours")} >
                                                {state.typeOfHoursOptions}
                                            </select>
                                            {/*<select className="form-control-custom" {...register("formFields.typeOfHours")} disabled={state.prevailingWageJob === '' ? true : false}>*/}
                                            {/*    {state.typeOfHoursOptions}                                          */}
                                            {/*</select>*/}
                                        </div>
                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Awarding Party</label>
                                            <input type="text" {...register("formFields.awardingParty")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Contact Person</label>
                                            <input type="text" {...register("formFields.jobsiteContactPerson")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Contact Phone #</label>
                                            <input type="text" {...register("formFields.jobsiteContactPhone")} className="form-control-custom" />
                                        </div>

                                        <div className="form-group col-lg-12">
                                            <label className="form-label">Description</label>
                                            <textarea name="description" rows="4" cols="50" {...register("formFields.workDescription")} className="form-control-custom" />
                                        </div>

                                        <div className="form-group col-lg-6">
                                            <label className="form-label color-dark-blue">UNION BIDDERS</label>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label className="form-label color-dark-blue">NON UNION BIDDERS</label>
                                        </div>

                                        <div className="form-group col-lg-6">
                                            <input type="text" {...register("formFields.unionBidder1")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <input type="text" {...register("formFields.nonUnionBidder1")} className="form-control-custom" />
                                        </div>

                                        <div className="form-group col-lg-6">
                                            <input type="text" {...register("formFields.unionBidder2")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <input type="text" {...register("formFields.nonUnionBidder2")} className="form-control-custom" />
                                        </div>

                                        <div className="form-group col-lg-6">
                                            <input type="text" {...register("formFields.unionBidder3")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <input type="text" {...register("formFields.nonUnionBidder3")} className="form-control-custom" />
                                        </div>
                                    </div>
                                </form>
                            </Fragment>
                        }
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-submit margin-left-15 float-left" onClick={handleSubmitRequest} disabled={state.processingNewRequest}>Submit Request</button>&nbsp;&nbsp;&nbsp;
                        <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                    </div>
                </div>

            </SlidingPane>
        
        </>
    );
}