import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import statesList from '../Helpers/StatesList';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { useForm, Controller } from "react-hook-form";
import MRRequestHistorySlider from './MRRequestHistorySlider';
import ReassignToJobSlider from './ReassignToJobSlider';
import Globals from '../../config/globals';
import { Modal, message } from 'antd';

/*import { useForm, Controller } from "react-hook-form";*/

import { Select } from 'antd';

export default function MRRequestDetailsSlider(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { Option } = Select;

    const [state, setState] = useState({
        showPane: props.showPane,
        selectedRequestId: '',
        selectedIdx: '',
        formFields: {},
        local777UseGrantedOrDenied: '',
        isHoursGrantedDisabled: false,
        showRequestHistorySlider: false,
        requests: [],
        showReassignToJobSlider: false
    });

    const [modalInfo, setModalInfo] = useState({
        showmodal: false,
        content: {}
    });

    //useEffect(() => {
    //    const subscription = watch((value, { name, type }) => {
    //        //console.log(value);
    //        //console.log(name);
    //        //console.log(type);
    //    });

    //    return () => subscription.unsubscribe();

    //}, []);

    useEffect(() => {
        if (props.showPane) {
            if (props.selectedRequestId !== null && props.selectedRequestId !== "") {
                Axios.get(`/api/GetRequestById`, {
                    params: {
                        requestID: props.selectedRequestId
                    }
                }).then(response => {
                    
                    setValue("formFields", response.data.request);
                    setValue("formFields.agentsList", response.data.agents);

                    setValue('jobs_ID', response.data.request.mrRequest.jobs_ID);
                    setValue('jobName', response.data.request.jobName);
                    setValue('originalRequestStatus', response.data.request.mrRequest.requestStatus)

                    setValue('contractorContacts', response.data.contractorContacts);

                    if (getValues().formFields.mrRequest.expectedStartDate === '0001-01-01T00:00:00' || getValues().formFields.mrRequest.expectedStartDate === null) {
                        setValue('formFields.mrRequest.expectedStartDate', 'N/A');
                    }
                    else {
                        setValue('formFields.mrRequest.expectedStartDate', Moment(dateWithNoTimezone(getValues().formFields?.mrRequest.expectedStartDate)).format("L"));
                    }

                    if (getValues().formFields.mrRequest.anticipatedCompletionDate === '0001-01-01T00:00:00' || getValues().formFields.mrRequest.anticipatedCompletionDate === null) {
                        setValue('formFields.mrRequest.anticipatedCompletionDate', 'N/A');
                    }
                    else {
                        setValue('formFields.mrRequest.anticipatedCompletionDate', Moment(dateWithNoTimezone(getValues().formFields?.mrRequest.anticipatedCompletionDate)).format("L"));
                    }

                    setState({
                        ...state,
                        formFields: response.data,
                        showPane: props.showPane,
                        requests: props.requests,
                        selectedRequestId: props.selectedRequestId,
                        selectedIdx: props.selectedIdx,
                        requests: props.requests,
                        showRequestHistorySlider: false,
                        showReassignToJobSlider: false
                    });

                    setLocal777UseOnlyFields();
                    
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }, [props]);

    function hidePane() {
        //now update the parent list
        let requests = props.requests;
        const selectedIdx = requests.findIndex(r => r.mrRequest.requestID === getValues().formFields.mrRequest.requestID);
        requests[selectedIdx] = getValues().formFields;

        //for graceful, animated close
        setTimeout(() => props.updateTableDisplay(requests, selectedIdx), 1000);

        setState({
            ...state,
            showPane: false,
            showRequestHistorySlider: false,
            showReassignToJobSlider: false
        });
    }

    function handleMRRequestInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            formFields: {
                ...state.formFields,
                mrRequest: {
                    ...state.formFields.mrRequest,
                    [name]: value
                }
            }
        });
    }

    function onLocal777UseOnlyStatusChanged(e) {
        setLocal777UseOnlyFields(e.currentTarget.value);
    }

    function onRequestStatusChanged(e) {
        console.log(e.currentTarget.value);

        if (e.currentTarget.value === 'granted') {
            setValue("formFields.local777UseGrantedOrDenied", 'granted');
        }
        else {
            setValue("formFields.local777UseGrantedOrDenied", 'denied');
            setValue("formFields.mrRequest.grantedHours", '');
            setValue("formFields.mrRequest.grantedBy", '');
            setValue("formFields.mrRequest.dollarsPerHour", '');
            setValue("formFields.mrRequest.dollarsPerHourApprentice", '');
        }
    }

    function setLocal777UseOnlyFields(myRequestStatus) {
        let requestStatus = myRequestStatus;
        let isHoursGrantedDisabled = false;

        if (getValues().formFields.mrRequest.requestStatus !== 'Pending') {
            if (getValues().formFields.mrRequest.grantedHours !== null && getValues().formFields.mrRequest.grantedHours !== '') {
                setValue('formFields.local777UseGrantedOrDenied', 'granted');
                isHoursGrantedDisabled = false;
            }
            else {
                setValue('formFields.local777UseGrantedOrDenied', 'denied');
                isHoursGrantedDisabled = true;
            }
        }
        else {
            setValue('formFields.local777UseGrantedOrDenied', '');
            setValue("formFields.mrRequest.dollarsPerHour", '');
            setValue("formFields.mrRequest.dollarsPerHourApprentice", '');
        }

        setState({
            ...state,
            local777UseGrantedOrDenied: requestStatus,
            isHoursGrantedDisabled,
            showPane: true
        });
    }

    function onReassignJobClick() {
        console.log('here in onReassignJobClick');

        setState({
            ...state,
            showReassignToJobSlider: true,
            showRequestHistorySlider: false
        });
    }

    function onResendEmailNotificationsClick() {
        if (getValues().formFields.contactEmail === '') {
            toast.error("No Contact Email is configured for the Contractor in their profile. Please correct the problem and Resend Email Notifications");
            return;
        }

        const key = "resendnotifications";
        message.loading({
            content: 'Sending Email Notifications...',
            key,
            duration: 0
        });

        let recipientList = '';
        getValues().contractorContacts.forEach((contact, idx) => {
            if (contact.contactEmail !== '' && contact.contactEmail !== null) {
                if (recipientList === '') {
                    recipientList += contact.contactEmail;
                }
                else {
                    recipientList += ',' + contact.contactEmail;
                }
            }
        });

        let postdata = {
            RequestID: getValues().formFields.mrRequest.requestID,
            EmailRecipients: recipientList,
            HistoryDescription: "Market Recovery notification emails were re-sent by " + userInfo.fullName,
            MCACMember: getValues().formFields?.contractorMCACMember,
            Username: userInfo.fullName,
            EmailNotificationType: 'NotifyContractor'
        }

        Axios.post(`/api/SendEmailNotificationsToContractor`, postdata
        ).then(response => {
            message.destroy(key);

            if (response.data === true) {
                toast.success("Email notifications were successfully sent");

                //this.hidePane();
            } else {
                toast.error("There was a problem sending email notifications");
            }
        }).catch(error => {
            console.log(error);
        });
    }

    //function onPrintRequestPDFClick() {

    //    let postdata = {
    //        RequestID: getValues().formFields.mrRequest.requestID
    //    }

    //    Axios.post(`/api/GetRequestPDF`, postdata, {
    //        responseType: 'blob'
    //    }).then(response => {
    //        //console.log(response);

    //        //open in new browser tab
    //        const file = new Blob(
    //            [response.data],
    //            { type: 'application/pdf' });

    //        const fileURL = URL.createObjectURL(file);

    //        var newWindow = window.open(fileURL);

    //        setTimeout(function () {
    //            newWindow.document.title = "Market Recovery Request";
    //        }, 1000);

    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    function onPrintRequestPDFClick() {
        let myFileName = "MRRequest_" + props.selectedRequestId;

        const key = "loading";
        message.loading({
            content: 'PDF generation in progress...',
            key,
            duration: 0,
            style: {
                position: "fixed",
                bottom: 0,
                left: 0
            },
        });

        let postdata = {
            RequestID: getValues().formFields.mrRequest.requestID
        }

        Axios.post(`/api/GetRequestPDF`, postdata, {
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = myFileName + ".pdf";
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);
        }).catch(error => {
            console.log(error);
        });
    }

    function DownloadDraftInvoicesReport() {
        let myFileName = "Draft Invoices Report";
        const key = "loading";

        let postdata = {
            reportName: myFileName
        }

        message.loading({
            content: 'Report generation in progress...',
            key,
            duration: 0,
            style: {
                position: "fixed",
                bottom: 0,
                left: 0
            },
        });

        Axios.post(`/api/DownloadDraftInvoicesReport`, postdata, {
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = myFileName + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);
        }).catch(error => {
            console.log(error);
        });
    }

    function onHistoryClick() {
        console.log(getValues().formFields.mrRequest.requestID);

        setState({
            ...state,
            showRequestHistorySlider: true,
            showReassignToJobSlider: false
        });
    }

    function onForwardToAgentClick() {
        var agentEmail = '';
        var agentName = '';

        /*let userInfo = Globals.userInfo;*/
        var forwardedDate = new Date();

        getValues().formFields?.agentsList.forEach((agent, idx) => {
            var i = agent.values;

            if (agent.users_ID.toString() === getValues().formFields.mrRequest.agentId.toString()) {
                agentEmail = agent.email;
                agentName = agent.firstName + ' ' + agent.lastName;
            }
        });

        if (agentEmail === '') {
            toast.error("No Agent was selected to forward the request to. Request not forwarded.");
            return;
        }

        const key = "forwardtoagent";
        message.loading({
            content: 'Forwarding request to ' + agentName + '...',
            key,
            duration: 0
        });

        let postdata = {
            RequestID: getValues().formFields.mrRequest.requestID,
            EmailRecipients: agentEmail,
            AgentName: agentName,
            Username: userInfo.fullName
        }

        Axios.post(`/api/ForwardRequestToAgent`, postdata
        ).then(response => {
            if (response.data === true) {
                message.destroy(key);

                setValue('formFields.mrRequest.lastForwarded', forwardedDate);

                setState({
                    ...state,
                    showRequestHistorySlider: false,
                    showReassignToJobSlider: false
                });

                toast.success('Email successfully forwarded to ' + agentName);
            } else {
                toast.error('There was a problem forwarding email to ' + agentName);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function closeModal() {
        setModalInfo({
            ...modalInfo,
            showmodal: false
        });
    }

    function openCompleteRequestModal() {
        setValue('crEmailContractor', true);

        setModalInfo({
            ...modalInfo,
            showmodal: true
        });
    }

    function onCompleteRequest() {
        let postdata = {
            RequestID: getValues().formFields.mrRequest.requestID,
            EmailContractor: getValues().crEmailContractor,
            Notes: getValues().crNotes,
            Username: userInfo.fullName,
            EmailRecipients: getValues().formFields?.contactEmail,
        }

        Axios.post(`/api/CompleteRequest`, postdata
        ).then(response => {
            setValue('formFields.mrRequest.requestStatus', 'Completed');

            setModalInfo({
                ...modalInfo,
                showmodal: false
            });

            if (response.data.includes("problem sending")) {
                toast.error(response.data);
            }
            else {
                toast.success(response.data);
            }

        }).catch(error => {
            console.log(error);
        });
    }

    function onApplyChanges() {
        let errString = '';

        if (getValues().jobs_ID === 0) {
            errString = "&#8226;&nbsp;You must assign this request to a Job.<br />";
            errString = "<strong>Market Recovery Request was not updated</strong><br />" + errString;
            toast.error(<span style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: errString }}></span>, { alignment: 'top-center', duration: 5 })

            return;
        }

        if (getValues().formFields.local777UseGrantedOrDenied === 'granted') {
            if (getValues().formFields.mrRequest.grantedHours === '') {
                errString += "&#8226;&nbsp;Hours Granted must be provided<br />";
            }
            if (getValues().formFields.mrRequest.dollarsPerHour === '') {
                errString += "&#8226;&nbsp;Dollars Per Hour must be provided<br />";
            }

            if (errString != '') {
                errString = "<strong>Market Recovery Request was not updated</strong><br />" + errString;

                toast.error(<span style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: errString }}></span>, { alignment: 'top-center', duration: 5 })

                return;
            }
            setValue('formFields.mrRequest.requestStatus', 'Granted');
            setValue('formFields.mrRequest.grantedDenied', 'G');
        }
        else if (getValues().formFields.local777UseGrantedOrDenied === 'denied') {
            if (getValues().formFields.mrRequest.reason === '') {
                errString += "&#8226;&nbsp;Reason for Denial must be provided<br />";
            }

            if (errString != '') {
                errString = "<strong>Market Recovery Request was not updated</strong><br />" + errString;

                toast.error(<span style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: errString }}></span>, { alignment: 'top-center', duration: 5 })

                return;
            }
            setValue('formFields.mrRequest.requestStatus', 'Denied');
            setValue('formFields.mrRequest.grantedDenied', 'D');
        }
        else {

        }

        setValue('formFields.mrRequest.jobs_ID', getValues().jobs_ID);

        //neded this logic, otherwise update fails
        if (getValues().formFields.mrRequest.dollarsPerHour === '') { setValue('formFields.mrRequest.dollarsPerHour', null); }
        if (getValues().formFields.mrRequest.dollarsPerHourApprentice === '') { setValue('formFields.mrRequest.dollarsPerHourApprentice', null); }

        //if (getValues().formFields.mrRequest.anticipatedCompletionDate === '0001-01-01T00:00:00') { setValue('formFields.mrRequest.anticipatedCompletionDate', null); }
        //if (getValues().formFields.mrRequest.archivePending === '0001-01-01T00:00:00') { setValue('formFields.mrRequest.archivePending', null); }
        //if (getValues().formFields.mrRequest.awardDate === '0001-01-01T00:00:00') { setValue('formFields.mrRequest.awardDate', null); }
        //if (getValues().formFields.mrRequest.awardNotificationSent === '0001-01-01T00:00:00') { setValue('formFields.mrRequest.awardNotificationSent', null); }
        //if (getValues().formFields.mrRequest.expectedStartDate === '0001-01-01T00:00:00') { setValue('formFields.mrRequest.expectedStartDate', null); }
        //if (getValues().formFields.mrRequest.bidDate === '0001-01-01T00:00:00') { setValue('formFields.mrRequest.bidDate', null); }
        //if (getValues().formFields.mrRequest.lastForwarded === '0001-01-01T00:00:00') { setValue('formFields.mrRequest.lastForwarded', null); }
        //if (getValues().formFields.mrRequest.requestDate === '0001-01-01T00:00:00') { setValue('formFields.mrRequest.requestDate', null); }

        let formFields = getValues().formFields.mrRequest;

        if (getValues().formFields.mrRequest.dollarsPerHour !== '' && getValues().formFields.mrRequest.dollarsPerHour !== null) {
            setValue('formFields.mrRequest.dollarsPerHour', getValues().formFields.mrRequest.dollarsPerHour.toString().replace("$", ""));
        }
        if (getValues().formFields.mrRequest.dollarsPerHourApprentice !== '' && getValues().formFields.mrRequest.dollarsPerHourApprentice !== null) {
            setValue('formFields.mrRequest.dollarsPerHourApprentice', getValues().formFields.mrRequest.dollarsPerHourApprentice.toString().replace("$", ""));
        }

        if (getValues().formFields.mrRequest.expectedStartDate === 'N/A') {
            setValue('formFields.mrRequest.expectedStartDate', null);
        }
        if (getValues().formFields.mrRequest.anticipatedCompletionDate === 'N/A') {
            setValue('formFields.mrRequest.anticipatedCompletionDate', null);
        }

        setState({
            ...state
        });

        if (getValues().formFields.mrRequest.agentId2 === '') {
            setValue('formFields.mrRequest.agentId2', null);
        }

        console.log(getValues().originalRequestStatus);
        console.log(getValues().formFields.mrRequest.requestStatus);

        Axios.post(`/api/UpdateRequest`, formFields
        ).then(response => {
            if (response.data) {
                if (getValues().formFields.mrRequest.requestStatus !== getValues().originalRequestStatus) {
                    const key = "resendnotifications";
                    message.loading({
                        content: 'Updating Request & Sending Email Notifications...',
                        key,
                        duration: 0
                    });

                    let recipientList = '';
                    getValues().contractorContacts.forEach((contact, idx) => {
                        if (contact.contactEmail !== '' && contact.contactEmail !== null) {
                            if (recipientList === '') {
                                recipientList += contact.contactEmail;
                            }
                            else {
                                recipientList += ',' + contact.contactEmail;
                            }
                        }
                    });

                    let postdata = {
                        RequestID: getValues().formFields.mrRequest.requestID,
                        RequestStatus: getValues().formFields.mrRequest.requestStatus,
                        HistoryDescription: "Status changed to '" + getValues().formFields.mrRequest.requestStatus + "' by " + userInfo.fullName,
                        EmailRecipients: recipientList,
                        MCACMember: getValues().formFields?.contractorMCACMember,
                        Username: userInfo.fullName,
                        EmailNotificationType: 'NotifyContractor'
                    }

                    Axios.post(`/api/SendEmailNotificationsToContractor`, postdata
                    ).then(response => {
                        message.destroy(key);

                        if (response.data === true) {
                            toast.success("Market Recovery Request successfully updated and Email notifications sent");

                        } else {
                            toast.error("There was a problem sending email notifications");
                        }
                    }).catch(error => {
                        console.log(error);
                    });


                    ////notify local777 recipient add history record for status change
                    //let postdata = {
                    //    RequestID: props.selectedRequestId,
                    //    RequestStatus: getValues().formFields.mrRequest.requestStatus,
                    //    HistoryDescription: "Status changed to '" + getValues().formFields.mrRequest.requestStatus + "' by " + userInfo.fullName
                    //}

                    //Axios.post(`/api/NotifyLocal777OfStatusChange`, postdata
                    //).then(response => {
                    //    toast.success('Market Recovery Request successfully updated and Email notifications sent');

                    //}).catch(error => {
                    //    console.log(error);
                    //});
                }
                else {
                    toast.success('Market Recovery Request successfully updated');
                }
            }

        }).catch(error => {
            console.log(error);
            toast.error('There was a problem updating Market Recovery Request');
        });
    }

    async function updateAssignedJob(selectedJobId = null) {
        await setState({ ...state, dataLoaded: false });

        LoadAssignedJobDetails(selectedJobId);

        setState({
            ...state,
            dataLoaded: true
        });
    }

    function LoadAssignedJobDetails(jobs_ID) {
        //console.log(jobs_ID);

        Axios.get(`/api/GetJobById`, {
            params: {
                jobs_ID: jobs_ID
            }
        }).then(response => {
            console.log(response.data);

            setValue('jobs_ID', response.data.jobs_ID);
            setValue('jobName', response.data.jobName);

            //console.log(getValues().jobs_ID);
            //console.log(getValues().jobName);

            setState({
                ...state,
                showRequestDetailsSlider: true,
                showReassignToJobSlider: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function handleAgentInputChange(event) {
        var agentName = '';

        const target = event.target;
        var value = null;
        if (target.type === 'checkbox') {
            value = target.checked === true ? 1 : 0;
        }
        else {
            value = target.value;
        }
        const name = target.name;

        setValue(name, value);

        if (name === 'formFields.mrRequest.agentId') {
            getValues().formFields?.agentsList.forEach((agent, idx) => {
                if (agent.users_ID.toString() === value) {
                    agentName = agent.firstName + ' ' + agent.lastName;
                    setValue('formFields.agentName', agentName);
                    setValue('formFields.mrRequest.agentId', value);
                }
            });
        }
        else if (name === 'formFields.mrRequest.agentId2') {
            if (value === '') {
                console.log('here');
                setValue(name, null);
            }
        }

        setState({
            ...state
        });
    }

    return (
        <>
            <SlidingPane
                className='some-custom-class w-65'
                overlayClassName='showCard'
                isOpen={state.showPane}
                title='Market Recovery Request Info'
                onRequestClose={hidePane}
            >
                <div className="slide-pane-body scrollableDiv">
                    <div>
                        {!state.loading &&
                            <Fragment>
                                {/*<div className="panel-hdr">*/}
                                {/*    {state.selectedRequestId && (<h2>{state.formFields.contactName}</h2>)}*/}
                                {/*    {!state.selectedRequestId && (<h2>New Market Recovery Request</h2>)}*/}
                                {/*</div>*/}
                                <Card className="local777Card">
                                    <Card.Header><span>Process Request for Market Recovery</span></Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Request Status:</label>
                                            </div>
                                            <div className="col-lg-10" style={{ color: "red" }}>
                                                <span>{getValues().formFields?.mrRequest.requestStatus}</span>
                                            </div>

                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Contractor:</label>
                                            </div>
                                            <div className="col-lg-10">
                                                <span>{getValues().formFields?.contractorName}</span>
                                            </div>

                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Contact Name:</label>
                                            </div>
                                            <div className="col-lg-4">
                                                <span>{getValues().formFields?.contactName}</span>
                                            </div>

                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Contact Title:</label>
                                            </div>
                                            <div className="col-lg-4">
                                                <span>{getValues().formFields?.contactTitle}</span>
                                            </div>

                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Contact Email:</label>
                                            </div>
                                            <div className="col-lg-10">
                                                <span>{getValues().formFields?.contactEmail}</span>
                                            </div>

                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Phone #:</label>
                                            </div>
                                            <div className="col-lg-4">
                                                <span>{getValues().formFields?.contractorPhone}</span>
                                            </div>

                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Fax #:</label>
                                            </div>
                                            <div className="col-lg-4">
                                                <span>{getValues().formFields?.contractorFax}</span>
                                            </div>

                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Bid Date:</label>
                                            </div>
                                            <div className="col-lg-10">
                                                <span>{getValues().formFields?.mrRequest.bidDate && Moment(dateWithNoTimezone(getValues().formFields?.mrRequest?.bidDate)).format("L")}</span>
                                            </div>

                                            <div className="col-lg-2">
                                                <label className="form-label mb-0">Prevailing Wage Job:</label>
                                            </div>
                                            <div className="col-lg-10">
                                                <span>{getValues().formFields?.mrRequest.prevailingWageJob === 1 ? 'Yes' : 'No'}</span>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <Card className="local777Card">
                                    <Card.Header><span>Job Information</span></Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Project Name:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>{getValues().formFields?.mrRequest.projectName}</span>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Building/Suite/Floor:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>{getValues().formFields?.mrRequest.projectDetails}</span>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Job Site Address:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>{getValues().formFields?.mrRequest.jobsiteAddress}</span>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">City, State & Zip:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>{getValues().formFields?.mrRequest.jobsiteCityState}</span>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Type of Hours:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>{getValues().formFields?.mrRequest.typeOfHours}</span>
                                            </div>


                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Work Description:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>{getValues().formFields?.mrRequest.workDescription}</span>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Concessions Made:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>{getValues().formFields?.mrRequest.concessionsMade}</span>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Hours Summary:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>Requested: <strong>{getValues().formFields?.mrRequest.requestedHours}</strong>, Granted: <strong>{getValues().formFields?.mrRequest.grantedHours}</strong>, Hours Paid Manually: <strong>{getValues().formFields?.mrRequest.hoursPaidManually}</strong></span>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Awarding Party:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <span>{getValues().formFields?.mrRequest.awardingParty}. Contact: {getValues().formFields?.mrRequest.jobsiteContactPerson}, {getValues().formFields?.mrRequest.jobsiteContactPhone}</span>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Expecting Start Date:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                {getValues().formFields?.mrRequest.expectedStartDate}
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mb-0">Anticipated Completion Date:</label>
                                            </div>
                                            <div className="col-lg-9">
                                                {getValues().formFields?.mrRequest.anticipatedCompletionDate}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <div className="row">
                                    <div className="col col-lg-6 col-12">
                                        <Card className="local777Card">
                                            <Card.Header><span>Union Bidders</span></Card.Header>
                                            <Card.Body>
                                                <div className="row">
                                                    <div className="col-12">
                                                        {getValues().formFields?.mrRequest.unionBidder1}
                                                    </div>
                                                    <div className="col-12">
                                                        {getValues().formFields?.mrRequest.unionBidder2}
                                                    </div>
                                                    <div className="col-12">
                                                        {getValues().formFields?.mrRequest.unionBidder3}
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col col-lg-6 col-12">
                                        <Card className="local777Card">
                                            <Card.Header><span>Non Union Bidders</span></Card.Header>
                                            <Card.Body>
                                                <div className="row">
                                                    <div className="col-12">
                                                        {getValues().formFields?.mrRequest.nonUnionBidder1}
                                                    </div>
                                                    <div className="col-12">
                                                        {getValues().formFields?.mrRequest.nonUnionBidder2}
                                                    </div>
                                                    <div className="col-12">
                                                        {getValues().formFields?.mrRequest.nonUnionBidder3}
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                            </div>

                            <Card className="local777Card">
                                <Card.Header className="dark-header"><span>For Local 21 Use Only</span></Card.Header>
                                <Card.Body className="dark-body">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <label className="form-label mb-0">Current Status:</label>
                                        </div>
                                        <div className="col-lg-9" style={{ color: "red" }}>
                                            <span>{getValues().formFields?.mrRequest.requestStatus}</span>
                                        </div>

                                        <div className="form-group col-lg-3">
                                            <input type="radio" className="mt-1" name="local777UseGrantedOrDenied" value="granted" {...register('formFields.local777UseGrantedOrDenied')} onChange={onRequestStatusChanged} />&nbsp;<label className="form-label">Request Granted</label>&nbsp;&nbsp;&nbsp;
                                         </div>

                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Hours Granted</label>
                                            <input type="number" name="grantedHours" className="form-control-custom" {...register('formFields.mrRequest.grantedHours')} disabled={watch("formFields.local777UseGrantedOrDenied") === 'denied' || watch("formFields.local777UseGrantedOrDenied") === ''} />
                                        </div>

                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Granted By</label>
                                            <input type="text" className="form-control-custom" {...register('formFields.mrRequest.grantedBy')} disabled={watch("formFields.local777UseGrantedOrDenied") === 'denied' || watch("formFields.local777UseGrantedOrDenied") === ''} />
                                        </div>

                                        <div className="form-group col-lg-3">
                                        </div>

                                        <div className="form-group col-lg-3">
                                        </div>

                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Journeymen $/Hour</label>
                                            <Controller
                                                name="formFields.mrRequest.dollarsPerHour"
                                                control={control}
                                                defaultValue={getValues().formFields?.mrRequest.dollarsPerHour}
                                                render={({ field }) => <NumberFormat {...field} thousandSeparator={true} prefix={'$'} className="form-control-custom" onValueChange={(val) => setValue("formFields.mrRequest.dollarsPerHour", val.formattedValue)} disabled={watch("formFields.local777UseGrantedOrDenied") === 'denied' || watch("formFields.local777UseGrantedOrDenied") === ''} />}
                                            />
                                        </div>

                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Apprentice $/Hour</label>
                                            <Controller
                                                name="formFields.mrRequest.dollarsPerHourApprentice"
                                                control={control}
                                                defaultValue={getValues().formFields?.mrRequest.dollarsPerHourApprentice}
                                                render={({ field }) => <NumberFormat {...field} thousandSeparator={true} prefix={'$'} className="form-control-custom" onValueChange={(val) => setValue("formFields.mrRequest.dollarsPerHourApprentice", val.formattedValue)} disabled={watch("formFields.local777UseGrantedOrDenied") === 'denied' || watch("formFields.local777UseGrantedOrDenied") === ''} />}
                                            />

                                        {/*    <input type="text" className="form-control-custom" {...register('formFields.mrRequest.dollarsPerHourApprentice')} disabled={watch("formFields.local777UseGrantedOrDenied") === 'denied'} />*/}
                                        </div>

                                        <div className="form-group col-lg-3">
                                        </div>

                                        <div className="form-group col-lg-3">
                                            <input type="radio" className="mt-1" name="local777UseGrantedOrDenied" value="denied" {...register('formFields.local777UseGrantedOrDenied')} onChange={onRequestStatusChanged} />&nbsp;<label className="form-label">Request Denied</label>&nbsp;&nbsp;&nbsp;
                                         </div>

                                        <div className="form-group col-lg-6">
                                            <label className="form-label">Reason</label>
                                            <input type="text" className="form-control-custom" {...register('formFields.mrRequest.reason')} disabled={watch("formFields.local777UseGrantedOrDenied") === 'granted' || watch("formFields.local777UseGrantedOrDenied") === ''} />
                                        </div>

                                        <div className="form-group col-lg-3">
                                        </div>

                                        <div className="col-lg-3">
                                            <label className="form-label mb-0">Assigned Job:</label>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            {getValues().jobName ? getValues().jobName : <label className="form-label">This Request Has Not Been Assigned To A Job Yet</label>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            
                                            <span className="a hover" onClick={onReassignJobClick}><u>Re-Assign to Job</u></span>
                                        </div>
                                        <div className="col-lg-3"></div>

                                        <div className="col-lg-3 form-group">
                                            <label className="form-label">Assign to Agent:</label>
                                        </div>
                                        <div className="col-lg-2 form-group">
                                            <select className="form-control-custom" {...register('formFields.mrRequest.agentId')} value={getValues().formFields?.mrRequest.agentId} onChange={handleAgentInputChange}>
                                                <option value="">Select Agent</option>
                                                {getValues().formFields?.agentsList.length > 0 && getValues().formFields?.agentsList.map((agent, idx) => <option index={idx} key={agent.users_ID} value={agent.users_ID}>{agent.firstName} {agent.lastName}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <button className="btn btn-submit float-right" onClick={onForwardToAgentClick}>Forward to Agent</button>
                                        </div>
                                        <div className="form-group col-lg-5">
                                            {getValues().formFields?.mrRequest.lastForwarded !== null ? 'Request Last forwarded on ' + Moment(dateWithNoTimezone(getValues().formFields?.mrRequest.lastForwarded)).format("L") : 'Request Has Not Been Forwarded To Agent Yet'}
                                        </div>

                                        <div className="col-lg-3 form-group">
                                            <label className="form-label">Secondary Agent:</label>
                                        </div>
                                        <div className="col-lg-2 form-group">
                                            <select className="form-control-custom" {...register('formFields.mrRequest.agentId2')} value={getValues().formFields?.mrRequest.agentId2} onChange={handleAgentInputChange}>
                                                <option value="">Select Agent</option>
                                                {getValues().formFields?.agentsList.length > 0 && getValues().formFields?.agentsList.map((agent, idx) => <option index={idx} key={agent.users_ID} value={agent.users_ID}>{agent.firstName} {agent.lastName}</option>)}
                                            </select>
                                        </div>
                                        <div className="col-lg-7"></div>

                                        <div className="col-lg-3">
                                            <label className="form-label mb-0">Comments:</label>
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control-custom" {...register('formFields.mrRequest.comments')} />
                                        </div>
                                        <div className="col-lg-3"></div>
                                    </div>
                                    <div className="row">
                                        <div>
                                            &nbsp;
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            </Fragment>
                        }
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <span><button className="btn btn-submit btn-sm" onClick={onApplyChanges}>Apply Changes</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span style={getValues().formFields?.mrRequest.requestStatus === 'Completed' ? { display: 'none' } : {}}><button className="btn btn-submit btn-sm" onClick={openCompleteRequestModal}>Mark Request Complete</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span><button className="btn btn-submit btn-sm" onClick={onPrintRequestPDFClick}>Print PDF</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span style={getValues().formFields?.mrRequest.requestStatus === 'Pending' ? { display: 'none' } : {}}><button className="btn btn-submit btn-sm" onClick={onResendEmailNotificationsClick}>Resend Email Notifications</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span><button className="btn btn-submit btn-sm" onClick={onHistoryClick}>History</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                    </div>
                </div>

                {state.showRequestHistorySlider &&
                    <MRRequestHistorySlider showPane={state.showRequestHistorySlider} selectedRequestId={getValues().formFields.mrRequest.requestID} />
                }
                {state.showReassignToJobSlider &&
                    <ReassignToJobSlider showPane={state.showReassignToJobSlider} selectedRequestId={getValues().formFields.mrRequest.requestID} projectName={getValues().formFields.mrRequest.projectName}
                        projectDetails={getValues().formFields.mrRequest.projectDetails} jobsiteAddress={getValues().formFields.mrRequest.jobsiteAddress} jobsiteCityState={getValues().formFields.mrRequest.jobsiteCityState}
                        selectedJobId={getValues().formFields.mrRequest.jobs_ID} updateAssignedJob={updateAssignedJob} />
                }
            </SlidingPane>
            <Modal
                visible={modalInfo.showmodal}
                title="Set Market Request Completed"
                onCancel={closeModal}
                footer={[
                    <button className="btn btn-submit" onClick={onCompleteRequest}>
                        Complete Request
                    </button>,
                    <span>&nbsp;&nbsp;</span>,
                    <button className="btn btn-outline-primary" onClick={closeModal}>
                        Close
                    </button>
                ]}
            >
                <div className="row">
                    <div className="col-lg-1">
                        <label className="form-label mb-0">Notes:</label>
                    </div>
                    <div className="form-group col-lg-12">
                        <textarea rows="4" {...register("crNotes")} className="form-control-custom" />
                    </div>

                    <div className="form-group col-lg-12">
                        <div className="custom-control custom-checkbox custom-control-inline">
                            <input {...register("crEmailContractor")} type="checkbox" className="custom-control-input" style={{ marginTop: 5 }} />&nbsp;&nbsp;
                            <label className="custom-control-label">Email Contractor</label>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}